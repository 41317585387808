.reviews-wrapper .site-layout {
    max-width: 1440px;
    overflow: hidden;
    position: relative;
    z-index: 1;
}
.reviews-content-img {
    position: relative;
    @media screen and (max-width: 768px) {
        top: -40px;
    }
}

.reviews-content {
    position: relative;
    z-index: 1;
    .title {
        color: #102746;
        font-size: 35px;
        line-height: 1.2;
        font-family: 'LatoMedium', sans-serif;
        position: absolute;
        top: 57px;
        left: 89px;
        z-index: 1;
        &::before {
            content: "";
            position: absolute;
            z-index: 2;
            border-bottom: 2px solid #006DFE;
            width: 290px;
            bottom: -8px;
        }
        @media screen and (max-width: 1280px) {
            font-size: 30px;
            &::before {
                width: 250px;
                bottom: -8px;
            }
        }
        @media screen and (max-width: 768px) {
            font-size: 24px;
            padding: 0;
            position: relative;
            margin: 0 15px 40px;
            top: 0;
            left: 0;
            &::before {
                width: 100%;
                bottom: -8px;
            }
        }
    }

    &__img {
        @media screen and (max-width: 768px) {
            display: block;
            max-width: 100%;
            height: auto;
        }
    }
}

.reviews-slider {
    width: 100%;
    max-width: 1120px;
    position: absolute!important;
    top: 50%;
    left: 340px;
    transform: translateY(-50%);
    z-index: 2;
    @media screen and (max-width: 1024px) {
        left: 240px;
    }

    @media screen and (max-width: 768px) {
        position: relative!important;
        top: 0;
        left: 0;
        right: 0;
        max-width: 290px;
        transform: translateY(0%);
    }

    .slick-track {
        padding: 60px 0;
        @media screen and (max-width: 768px) {
            padding: 0;
        }
    }

    .slick-list {
        padding-left: 20px!important;
        @media screen and (max-width: 768px) {
            padding: 0!important;
        }
    }

    .slick-dots {
        text-align: left;
        margin-left: 337px;
        @media screen and (max-width: 768px) {
            text-align: center;
            margin: 0!important;
            bottom: -40px;
        }
        li {
            margin-right: 20px;
        }
        li button::before {
            content: "";
            opacity: 1;
            color: transparent;
            background: transparent;
            border: 3px solid #fff;
            border-radius: 100%;
        }
        li.slick-active button:before {
            background: #fff;
        }
    }

    .slick-slide {
        width: 100%!important;
        max-width: 420px!important;
        min-height: 500px;
        padding: 100px 40px 50px;
        margin-left: 40px;
        background: #ffffff url("../img/bitmap/blue-quotes.svg") no-repeat 40px 50px;
        border-radius: 6px;
        box-sizing: border-box;
        box-shadow: 0 12px 30px 0 rgba(121, 121, 121, 0.3);
        @media screen and (max-width: 768px) {
            max-width: 290px!important;
            margin: 0;
            min-height: 610px;
            padding: 100px 15px 20px;
            background: #ffffff url("../img/bitmap/blue-quotes.svg") no-repeat 15px 50px;
        }
    }
    .slick-slide.slick-current{
        background: #006DFE url("../img/bitmap/white-quotes.svg") no-repeat 40px 50px;
        box-shadow: 0 12px 30px 0 rgba(0, 109, 254, 0.5);
        @media screen and (max-width: 768px) {
            max-width: 290px!important;
            background: #006DFE url("../img/bitmap/white-quotes.svg") no-repeat 15px 50px;
        }
        .slider-block {
            .text {
                color: #ffffff;
            }

            .author-content {
                .name {
                    color: #ffffff;
                }
                .surname {
                    color: #ffffff;
                }
            }
        }
    }
}

.slider-block {
    .text {
        font-size: 16px;
        line-height: 1.7;
        margin-bottom: 20px;
        color: rgba(0, 0, 0, .7);
        font-family: "Lato", sans-serif;
        @media screen and (max-width: 1280px) {
            font-size: 14px;
        }
        @media screen and (max-width: 768px) {
            font-size: 16px;
        }
    }

    .author {
        display: flex;
        &-content {
            .name {
                font-size: 18px;
                line-height: 1.22;
                margin-bottom: 5px;
                color: #000;
                font-family: "Lato", sans-serif;
                @media screen and (max-width: 1280px) {
                    font-size: 16px;
                }
            }
            
            .surname {
                font-size: 10px;
                line-height: 1.5;
                color: #999999;
                font-family: "LatoBold", sans-serif;
            }
        }

        .image {
            display: block;
            margin-right: 18px;
        }
    }
}

.wrapper-slider {
    @media screen and (max-width: 768px) {
        display: flex;
        flex-direction: column-reverse;
    }
}
.slick-dotted.slick-slider {
    margin: 0 auto!important;
}