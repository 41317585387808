.about-wrapper {
    padding: 100px 0;
    @media screen and (max-width: 1280px) {
        padding: 40px 15px;
    }

    .title {
        color: #102746;
        font-size: 35px;
        line-height: 1.2;
        margin-bottom: 41px;
        font-family: 'LatoMedium', sans-serif;
        position: relative;
        z-index: 1;
        &::before {
            content: "";
            position: absolute;
            z-index: 2;
            border-bottom: 2px solid #006DFE;
            width: 291px;
            bottom: -8px;
        }
        @media screen and (max-width: 1280px) {
            font-size: 30px;
            &::before {
                width: 270px;
                bottom: -8px;
            }
        }
        @media screen and (max-width: 768px) {
            font-size: 24px;
            &::before {
                width: 100%;
                bottom: -8px;
            }
        }
    }

    .about {
        &-list {
            display: flex;
            justify-content: space-between;
            align-items: baseline;
            flex-wrap: wrap;

        }

        &__items {
            max-width: 280px;
            @media screen and (max-width: 1280px) {
                max-width: 400px;
            }
            @media screen and (max-width: 1024px) {
                max-width: 350px;
            }
            @media screen and (max-width: 768px) {
                margin-bottom: 18px;
                border-bottom: 1px solid #C4C4C4;
            }
            &-last {
                @media screen and (max-width: 768px) {
                    margin-bottom: 0;
                    border-bottom: 0 none;
                }
            }
        }

        &__desc {
            font-size: 16px;
            line-height: 1.6;
            color: #000;
            font-family: 'Lato', sans-serif;
            @media screen and (max-width: 1280px) {
                font-size: 14px;
                padding-bottom: 10px;
            }
            @media screen and (max-width: 768px) {
                font-size: 16px;
                padding-bottom: 18px;
            }
        }

    }
}

.about-content {
    max-width: 1262px;
    margin: auto;
}