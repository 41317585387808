@charset "UTF-8";
@import url(../../node_modules/normalize.css/normalize.css);
@import url(../../node_modules/reset-css/reset.css);
@import url(../../node_modules/animate.css/animate.css);
/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
.hamburger {
  padding: 15px 15px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible; }
  .hamburger:hover {
    opacity: 0.7; }
  .hamburger.is-active:hover {
    opacity: 0.7; }
  .hamburger.is-active .hamburger-inner,
  .hamburger.is-active .hamburger-inner::before,
  .hamburger.is-active .hamburger-inner::after {
    background-color: #000; }

.hamburger-box {
  width: 40px;
  height: 24px;
  display: inline-block;
  position: relative; }

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px; }
  .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
    width: 40px;
    height: 4px;
    background-color: #000;
    border-radius: 4px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease; }
  .hamburger-inner::before, .hamburger-inner::after {
    content: "";
    display: block; }
  .hamburger-inner::before {
    top: -10px; }
  .hamburger-inner::after {
    bottom: -10px; }

/*
   * 3DX
   */
.hamburger--3dx .hamburger-box {
  perspective: 80px; }

.hamburger--3dx .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .hamburger--3dx .hamburger-inner::before, .hamburger--3dx .hamburger-inner::after {
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dx.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateY(180deg); }
  .hamburger--3dx.is-active .hamburger-inner::before {
    transform: translate3d(0, 10px, 0) rotate(45deg); }
  .hamburger--3dx.is-active .hamburger-inner::after {
    transform: translate3d(0, -10px, 0) rotate(-45deg); }

/*
   * 3DX Reverse
   */
.hamburger--3dx-r .hamburger-box {
  perspective: 80px; }

.hamburger--3dx-r .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .hamburger--3dx-r .hamburger-inner::before, .hamburger--3dx-r .hamburger-inner::after {
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dx-r.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateY(-180deg); }
  .hamburger--3dx-r.is-active .hamburger-inner::before {
    transform: translate3d(0, 10px, 0) rotate(45deg); }
  .hamburger--3dx-r.is-active .hamburger-inner::after {
    transform: translate3d(0, -10px, 0) rotate(-45deg); }

/*
   * 3DY
   */
.hamburger--3dy .hamburger-box {
  perspective: 80px; }

.hamburger--3dy .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .hamburger--3dy .hamburger-inner::before, .hamburger--3dy .hamburger-inner::after {
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dy.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateX(-180deg); }
  .hamburger--3dy.is-active .hamburger-inner::before {
    transform: translate3d(0, 10px, 0) rotate(45deg); }
  .hamburger--3dy.is-active .hamburger-inner::after {
    transform: translate3d(0, -10px, 0) rotate(-45deg); }

/*
   * 3DY Reverse
   */
.hamburger--3dy-r .hamburger-box {
  perspective: 80px; }

.hamburger--3dy-r .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .hamburger--3dy-r .hamburger-inner::before, .hamburger--3dy-r .hamburger-inner::after {
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dy-r.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateX(180deg); }
  .hamburger--3dy-r.is-active .hamburger-inner::before {
    transform: translate3d(0, 10px, 0) rotate(45deg); }
  .hamburger--3dy-r.is-active .hamburger-inner::after {
    transform: translate3d(0, -10px, 0) rotate(-45deg); }

/*
   * 3DXY
   */
.hamburger--3dxy .hamburger-box {
  perspective: 80px; }

.hamburger--3dxy .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .hamburger--3dxy .hamburger-inner::before, .hamburger--3dxy .hamburger-inner::after {
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dxy.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateX(180deg) rotateY(180deg); }
  .hamburger--3dxy.is-active .hamburger-inner::before {
    transform: translate3d(0, 10px, 0) rotate(45deg); }
  .hamburger--3dxy.is-active .hamburger-inner::after {
    transform: translate3d(0, -10px, 0) rotate(-45deg); }

/*
   * 3DXY Reverse
   */
.hamburger--3dxy-r .hamburger-box {
  perspective: 80px; }

.hamburger--3dxy-r .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .hamburger--3dxy-r .hamburger-inner::before, .hamburger--3dxy-r .hamburger-inner::after {
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dxy-r.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateX(180deg) rotateY(180deg) rotateZ(-180deg); }
  .hamburger--3dxy-r.is-active .hamburger-inner::before {
    transform: translate3d(0, 10px, 0) rotate(45deg); }
  .hamburger--3dxy-r.is-active .hamburger-inner::after {
    transform: translate3d(0, -10px, 0) rotate(-45deg); }

/*
   * Arrow
   */
.hamburger--arrow.is-active .hamburger-inner::before {
  transform: translate3d(-8px, 0, 0) rotate(-45deg) scale(0.7, 1); }

.hamburger--arrow.is-active .hamburger-inner::after {
  transform: translate3d(-8px, 0, 0) rotate(45deg) scale(0.7, 1); }

/*
   * Arrow Right
   */
.hamburger--arrow-r.is-active .hamburger-inner::before {
  transform: translate3d(8px, 0, 0) rotate(45deg) scale(0.7, 1); }

.hamburger--arrow-r.is-active .hamburger-inner::after {
  transform: translate3d(8px, 0, 0) rotate(-45deg) scale(0.7, 1); }

/*
   * Arrow Alt
   */
.hamburger--arrowalt .hamburger-inner::before {
  transition: top 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1); }

.hamburger--arrowalt .hamburger-inner::after {
  transition: bottom 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1); }

.hamburger--arrowalt.is-active .hamburger-inner::before {
  top: 0;
  transform: translate3d(-8px, -10px, 0) rotate(-45deg) scale(0.7, 1);
  transition: top 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22); }

.hamburger--arrowalt.is-active .hamburger-inner::after {
  bottom: 0;
  transform: translate3d(-8px, 10px, 0) rotate(45deg) scale(0.7, 1);
  transition: bottom 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22); }

/*
   * Arrow Alt Right
   */
.hamburger--arrowalt-r .hamburger-inner::before {
  transition: top 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1); }

.hamburger--arrowalt-r .hamburger-inner::after {
  transition: bottom 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1); }

.hamburger--arrowalt-r.is-active .hamburger-inner::before {
  top: 0;
  transform: translate3d(8px, -10px, 0) rotate(45deg) scale(0.7, 1);
  transition: top 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22); }

.hamburger--arrowalt-r.is-active .hamburger-inner::after {
  bottom: 0;
  transform: translate3d(8px, 10px, 0) rotate(-45deg) scale(0.7, 1);
  transition: bottom 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22); }

/*
   * Arrow Turn
   */
.hamburger--arrowturn.is-active .hamburger-inner {
  transform: rotate(-180deg); }
  .hamburger--arrowturn.is-active .hamburger-inner::before {
    transform: translate3d(8px, 0, 0) rotate(45deg) scale(0.7, 1); }
  .hamburger--arrowturn.is-active .hamburger-inner::after {
    transform: translate3d(8px, 0, 0) rotate(-45deg) scale(0.7, 1); }

/*
   * Arrow Turn Right
   */
.hamburger--arrowturn-r.is-active .hamburger-inner {
  transform: rotate(-180deg); }
  .hamburger--arrowturn-r.is-active .hamburger-inner::before {
    transform: translate3d(-8px, 0, 0) rotate(-45deg) scale(0.7, 1); }
  .hamburger--arrowturn-r.is-active .hamburger-inner::after {
    transform: translate3d(-8px, 0, 0) rotate(45deg) scale(0.7, 1); }

/*
   * Boring
   */
.hamburger--boring .hamburger-inner, .hamburger--boring .hamburger-inner::before, .hamburger--boring .hamburger-inner::after {
  transition-property: none; }

.hamburger--boring.is-active .hamburger-inner {
  transform: rotate(45deg); }
  .hamburger--boring.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0; }
  .hamburger--boring.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(-90deg); }

/*
   * Collapse
   */
.hamburger--collapse .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0.13s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--collapse .hamburger-inner::after {
    top: -20px;
    transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear; }
  .hamburger--collapse .hamburger-inner::before {
    transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--collapse.is-active .hamburger-inner {
  transform: translate3d(0, -10px, 0) rotate(-45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--collapse.is-active .hamburger-inner::after {
    top: 0;
    opacity: 0;
    transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear; }
  .hamburger--collapse.is-active .hamburger-inner::before {
    top: 0;
    transform: rotate(-90deg);
    transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Collapse Reverse
   */
.hamburger--collapse-r .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0.13s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--collapse-r .hamburger-inner::after {
    top: -20px;
    transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear; }
  .hamburger--collapse-r .hamburger-inner::before {
    transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--collapse-r.is-active .hamburger-inner {
  transform: translate3d(0, -10px, 0) rotate(45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--collapse-r.is-active .hamburger-inner::after {
    top: 0;
    opacity: 0;
    transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear; }
  .hamburger--collapse-r.is-active .hamburger-inner::before {
    top: 0;
    transform: rotate(90deg);
    transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Elastic
   */
.hamburger--elastic .hamburger-inner {
  top: 2px;
  transition-duration: 0.275s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55); }
  .hamburger--elastic .hamburger-inner::before {
    top: 10px;
    transition: opacity 0.125s 0.275s ease; }
  .hamburger--elastic .hamburger-inner::after {
    top: 20px;
    transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55); }

.hamburger--elastic.is-active .hamburger-inner {
  transform: translate3d(0, 10px, 0) rotate(135deg);
  transition-delay: 0.075s; }
  .hamburger--elastic.is-active .hamburger-inner::before {
    transition-delay: 0s;
    opacity: 0; }
  .hamburger--elastic.is-active .hamburger-inner::after {
    transform: translate3d(0, -20px, 0) rotate(-270deg);
    transition-delay: 0.075s; }

/*
   * Elastic Reverse
   */
.hamburger--elastic-r .hamburger-inner {
  top: 2px;
  transition-duration: 0.275s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55); }
  .hamburger--elastic-r .hamburger-inner::before {
    top: 10px;
    transition: opacity 0.125s 0.275s ease; }
  .hamburger--elastic-r .hamburger-inner::after {
    top: 20px;
    transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55); }

.hamburger--elastic-r.is-active .hamburger-inner {
  transform: translate3d(0, 10px, 0) rotate(-135deg);
  transition-delay: 0.075s; }
  .hamburger--elastic-r.is-active .hamburger-inner::before {
    transition-delay: 0s;
    opacity: 0; }
  .hamburger--elastic-r.is-active .hamburger-inner::after {
    transform: translate3d(0, -20px, 0) rotate(270deg);
    transition-delay: 0.075s; }

/*
   * Emphatic
   */
.hamburger--emphatic {
  overflow: hidden; }
  .hamburger--emphatic .hamburger-inner {
    transition: background-color 0.125s 0.175s ease-in; }
    .hamburger--emphatic .hamburger-inner::before {
      left: 0;
      transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, left 0.125s 0.175s ease-in; }
    .hamburger--emphatic .hamburger-inner::after {
      top: 10px;
      right: 0;
      transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, right 0.125s 0.175s ease-in; }
  .hamburger--emphatic.is-active .hamburger-inner {
    transition-delay: 0s;
    transition-timing-function: ease-out;
    background-color: transparent !important; }
    .hamburger--emphatic.is-active .hamburger-inner::before {
      left: -80px;
      top: -80px;
      transform: translate3d(80px, 80px, 0) rotate(45deg);
      transition: left 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1); }
    .hamburger--emphatic.is-active .hamburger-inner::after {
      right: -80px;
      top: -80px;
      transform: translate3d(-80px, 80px, 0) rotate(-45deg);
      transition: right 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1); }

/*
   * Emphatic Reverse
   */
.hamburger--emphatic-r {
  overflow: hidden; }
  .hamburger--emphatic-r .hamburger-inner {
    transition: background-color 0.125s 0.175s ease-in; }
    .hamburger--emphatic-r .hamburger-inner::before {
      left: 0;
      transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, left 0.125s 0.175s ease-in; }
    .hamburger--emphatic-r .hamburger-inner::after {
      top: 10px;
      right: 0;
      transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, right 0.125s 0.175s ease-in; }
  .hamburger--emphatic-r.is-active .hamburger-inner {
    transition-delay: 0s;
    transition-timing-function: ease-out;
    background-color: transparent !important; }
    .hamburger--emphatic-r.is-active .hamburger-inner::before {
      left: -80px;
      top: 80px;
      transform: translate3d(80px, -80px, 0) rotate(-45deg);
      transition: left 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1); }
    .hamburger--emphatic-r.is-active .hamburger-inner::after {
      right: -80px;
      top: 80px;
      transform: translate3d(-80px, -80px, 0) rotate(45deg);
      transition: right 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1); }

/*
   * Minus
   */
.hamburger--minus .hamburger-inner::before, .hamburger--minus .hamburger-inner::after {
  transition: bottom 0.08s 0s ease-out, top 0.08s 0s ease-out, opacity 0s linear; }

.hamburger--minus.is-active .hamburger-inner::before, .hamburger--minus.is-active .hamburger-inner::after {
  opacity: 0;
  transition: bottom 0.08s ease-out, top 0.08s ease-out, opacity 0s 0.08s linear; }

.hamburger--minus.is-active .hamburger-inner::before {
  top: 0; }

.hamburger--minus.is-active .hamburger-inner::after {
  bottom: 0; }

/*
   * Slider
   */
.hamburger--slider .hamburger-inner {
  top: 2px; }
  .hamburger--slider .hamburger-inner::before {
    top: 10px;
    transition-property: transform, opacity;
    transition-timing-function: ease;
    transition-duration: 0.15s; }
  .hamburger--slider .hamburger-inner::after {
    top: 20px; }

.hamburger--slider.is-active .hamburger-inner {
  transform: translate3d(0, 10px, 0) rotate(45deg); }
  .hamburger--slider.is-active .hamburger-inner::before {
    transform: rotate(-45deg) translate3d(-5.71429px, -6px, 0);
    opacity: 0; }
  .hamburger--slider.is-active .hamburger-inner::after {
    transform: translate3d(0, -20px, 0) rotate(-90deg); }

/*
   * Slider Reverse
   */
.hamburger--slider-r .hamburger-inner {
  top: 2px; }
  .hamburger--slider-r .hamburger-inner::before {
    top: 10px;
    transition-property: transform, opacity;
    transition-timing-function: ease;
    transition-duration: 0.15s; }
  .hamburger--slider-r .hamburger-inner::after {
    top: 20px; }

.hamburger--slider-r.is-active .hamburger-inner {
  transform: translate3d(0, 10px, 0) rotate(-45deg); }
  .hamburger--slider-r.is-active .hamburger-inner::before {
    transform: rotate(45deg) translate3d(5.71429px, -6px, 0);
    opacity: 0; }
  .hamburger--slider-r.is-active .hamburger-inner::after {
    transform: translate3d(0, -20px, 0) rotate(90deg); }

/*
   * Spin
   */
.hamburger--spin .hamburger-inner {
  transition-duration: 0.22s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--spin .hamburger-inner::before {
    transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in; }
  .hamburger--spin .hamburger-inner::after {
    transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--spin.is-active .hamburger-inner {
  transform: rotate(225deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--spin.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0;
    transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out; }
  .hamburger--spin.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(-90deg);
    transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Spin Reverse
   */
.hamburger--spin-r .hamburger-inner {
  transition-duration: 0.22s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--spin-r .hamburger-inner::before {
    transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in; }
  .hamburger--spin-r .hamburger-inner::after {
    transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--spin-r.is-active .hamburger-inner {
  transform: rotate(-225deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--spin-r.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0;
    transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out; }
  .hamburger--spin-r.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(90deg);
    transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Spring
   */
.hamburger--spring .hamburger-inner {
  top: 2px;
  transition: background-color 0s 0.13s linear; }
  .hamburger--spring .hamburger-inner::before {
    top: 10px;
    transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--spring .hamburger-inner::after {
    top: 20px;
    transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--spring.is-active .hamburger-inner {
  transition-delay: 0.22s;
  background-color: transparent !important; }
  .hamburger--spring.is-active .hamburger-inner::before {
    top: 0;
    transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(0, 10px, 0) rotate(45deg); }
  .hamburger--spring.is-active .hamburger-inner::after {
    top: 0;
    transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(0, 10px, 0) rotate(-45deg); }

/*
   * Spring Reverse
   */
.hamburger--spring-r .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--spring-r .hamburger-inner::after {
    top: -20px;
    transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0s linear; }
  .hamburger--spring-r .hamburger-inner::before {
    transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--spring-r.is-active .hamburger-inner {
  transform: translate3d(0, -10px, 0) rotate(-45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--spring-r.is-active .hamburger-inner::after {
    top: 0;
    opacity: 0;
    transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0s 0.22s linear; }
  .hamburger--spring-r.is-active .hamburger-inner::before {
    top: 0;
    transform: rotate(90deg);
    transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Stand
   */
.hamburger--stand .hamburger-inner {
  transition: transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0s 0.075s linear; }
  .hamburger--stand .hamburger-inner::before {
    transition: top 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--stand .hamburger-inner::after {
    transition: bottom 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--stand.is-active .hamburger-inner {
  transform: rotate(90deg);
  background-color: transparent !important;
  transition: transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0s 0.15s linear; }
  .hamburger--stand.is-active .hamburger-inner::before {
    top: 0;
    transform: rotate(-45deg);
    transition: top 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--stand.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(45deg);
    transition: bottom 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Stand Reverse
   */
.hamburger--stand-r .hamburger-inner {
  transition: transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0s 0.075s linear; }
  .hamburger--stand-r .hamburger-inner::before {
    transition: top 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--stand-r .hamburger-inner::after {
    transition: bottom 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--stand-r.is-active .hamburger-inner {
  transform: rotate(-90deg);
  background-color: transparent !important;
  transition: transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0s 0.15s linear; }
  .hamburger--stand-r.is-active .hamburger-inner::before {
    top: 0;
    transform: rotate(-45deg);
    transition: top 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--stand-r.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(45deg);
    transition: bottom 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Squeeze
   */
.hamburger--squeeze .hamburger-inner {
  transition-duration: 0.075s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--squeeze .hamburger-inner::before {
    transition: top 0.075s 0.12s ease, opacity 0.075s ease; }
  .hamburger--squeeze .hamburger-inner::after {
    transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--squeeze.is-active .hamburger-inner {
  transform: rotate(45deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--squeeze.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0;
    transition: top 0.075s ease, opacity 0.075s 0.12s ease; }
  .hamburger--squeeze.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(-90deg);
    transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Vortex
   */
.hamburger--vortex .hamburger-inner {
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }
  .hamburger--vortex .hamburger-inner::before, .hamburger--vortex .hamburger-inner::after {
    transition-duration: 0s;
    transition-delay: 0.1s;
    transition-timing-function: linear; }
  .hamburger--vortex .hamburger-inner::before {
    transition-property: top, opacity; }
  .hamburger--vortex .hamburger-inner::after {
    transition-property: bottom, transform; }

.hamburger--vortex.is-active .hamburger-inner {
  transform: rotate(765deg);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }
  .hamburger--vortex.is-active .hamburger-inner::before, .hamburger--vortex.is-active .hamburger-inner::after {
    transition-delay: 0s; }
  .hamburger--vortex.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0; }
  .hamburger--vortex.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(90deg); }

/*
   * Vortex Reverse
   */
.hamburger--vortex-r .hamburger-inner {
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }
  .hamburger--vortex-r .hamburger-inner::before, .hamburger--vortex-r .hamburger-inner::after {
    transition-duration: 0s;
    transition-delay: 0.1s;
    transition-timing-function: linear; }
  .hamburger--vortex-r .hamburger-inner::before {
    transition-property: top, opacity; }
  .hamburger--vortex-r .hamburger-inner::after {
    transition-property: bottom, transform; }

.hamburger--vortex-r.is-active .hamburger-inner {
  transform: rotate(-765deg);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }
  .hamburger--vortex-r.is-active .hamburger-inner::before, .hamburger--vortex-r.is-active .hamburger-inner::after {
    transition-delay: 0s; }
  .hamburger--vortex-r.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0; }
  .hamburger--vortex-r.is-active .hamburger-inner::after {
    bottom: 0;
    transform: rotate(-90deg); }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* Slider */
.slick-loading .slick-list {
  background: #fff url("./ajax-loader.gif") center center no-repeat; }

/* Icons */
@font-face {
  font-family: "slick";
  src: url("./fonts/slick.eot");
  src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"), url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none; }
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
    background: transparent;
    color: transparent; }
    .slick-prev:hover:before, .slick-prev:focus:before,
    .slick-next:hover:before,
    .slick-next:focus:before {
      opacity: 1; }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.25; }
  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 20px;
    line-height: 1;
    color: white;
    opacity: 0.75;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px; }
  [dir="rtl"] .slick-prev {
    left: auto;
    right: -25px; }
  .slick-prev:before {
    content: "←"; }
    [dir="rtl"] .slick-prev:before {
      content: "→"; }

.slick-next {
  right: -25px; }
  [dir="rtl"] .slick-next {
    left: -25px;
    right: auto; }
  .slick-next:before {
    content: "→"; }
    [dir="rtl"] .slick-next:before {
      content: "←"; }

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
        .slick-dots li button:hover:before, .slick-dots li button:focus:before {
          opacity: 1; }
      .slick-dots li button:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "•";
        width: 20px;
        height: 20px;
        font-family: "slick";
        font-size: 6px;
        line-height: 20px;
        text-align: center;
        color: black;
        opacity: 0.25;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    .slick-dots li.slick-active button:before {
      color: black;
      opacity: 0.75; }

/* Webfont: Lato-Light */
@font-face {
  font-family: 'LatoLight';
  src: url("../fonts/Lato-Light.eot");
  /* IE9 Compat Modes */
  src: url("../fonts/Lato-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/Lato-Light.woff2") format("woff2"), url("../fonts/Lato-Light.woff") format("woff"), url("../fonts/Lato-Light.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility; }

/* Webfont: Lato-Regular */
@font-face {
  font-family: 'Lato';
  src: url("../fonts/Lato-Regular.eot");
  /* IE9 Compat Modes */
  src: url("../fonts/Lato-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/Lato-Regular.woff2") format("woff2"), url("../fonts/Lato-Regular.woff") format("woff"), url("../fonts/Lato-Regular.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility; }

/* Webfont: Lato-Medium */
@font-face {
  font-family: 'LatoMedium';
  src: url("../fonts/Lato-Medium.eot");
  /* IE9 Compat Modes */
  src: url("../fonts/Lato-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/Lato-Medium.woff2") format("woff2"), url("../fonts/Lato-Medium.woff") format("woff"), url("../fonts/Lato-Medium.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility; }

/* Webfont: Lato-Semibold */
@font-face {
  font-family: 'LatoSemibold';
  src: url("../fonts/Lato-Semibold.eot");
  /* IE9 Compat Modes */
  src: url("../fonts/Lato-Semibold.eot?#iefix") format("embedded-opentype"), url("../fonts/Lato-Semibold.woff2") format("woff2"), url("../fonts/Lato-Semibold.woff") format("woff"), url("../fonts/Lato-Semibold.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility; }

/* Webfont: Lato-Bold */
@font-face {
  font-family: 'LatoBold';
  src: url("../fonts/Lato-Bold.eot");
  /* IE9 Compat Modes */
  src: url("../fonts/Lato-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/Lato-Bold.woff2") format("woff2"), url("../fonts/Lato-Bold.woff") format("woff"), url("../fonts/Lato-Bold.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility; }

.site-layout {
  width: 100%;
  max-width: 1440px;
  margin: auto; }

.header {
  padding: 0 15px;
  background-color: #090607;
  position: relative; }

.header-wrapper {
  width: 100%;
  max-width: 1062px;
  margin: auto;
  position: relative;
  z-index: 1; }
  .header-wrapper::before {
    position: absolute;
    content: "";
    z-index: -1;
    width: 1110px;
    height: 740px;
    background-image: url("../img/feature/main-feature.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: 160px;
    background-position-y: 0;
    top: 0;
    left: 128px; }
    @media screen and (max-width: 1280px) {
      .header-wrapper::before {
        background-image: none;
        background-position: 0 0;
        top: 0;
        left: 0;
        width: 0;
        height: 0; } }

.navigation {
  padding-top: 64px; }
  @media screen and (max-width: 1280px) {
    .navigation {
      padding-top: 40px;
      text-align: center; } }
  @media screen and (max-width: 768px) {
    .navigation {
      display: none; } }
  .navigation__item {
    display: inline-block;
    vertical-align: top;
    padding-right: 31px;
    font-family: 'Lato', sans-serif; }
    .navigation__item:last-child {
      padding-right: 0; }
  .navigation__link {
    font-size: 12px;
    line-height: 1.25;
    color: #FFFFFF;
    text-decoration: none;
    transition: .3s; }
    .navigation__link:hover {
      color: #006DFE;
      text-decoration: underline; }

.header-content {
  width: 100%;
  max-width: 620px;
  padding: 138px 0 316px; }
  @media screen and (max-width: 1280px) {
    .header-content {
      margin: auto;
      padding: 50px 0; } }
  @media screen and (max-width: 768px) {
    .header-content {
      margin: auto;
      padding: 10px 0 50px; } }
  .header-content .title {
    font-size: 50px;
    line-height: 1.2;
    margin-bottom: 22px;
    color: #FFFFFF;
    font-family: 'LatoMedium', sans-serif; }
    @media screen and (max-width: 768px) {
      .header-content .title {
        font-size: 32px;
        margin-bottom: 5px;
        max-width: 290px; } }
  .header-content .sub-title {
    font-size: 24px;
    line-height: 1.2;
    margin-bottom: 15px;
    color: #FFFFFF;
    font-family: 'LatoMedium', sans-serif; }
    @media screen and (max-width: 768px) {
      .header-content .sub-title {
        font-size: 18px;
        margin-bottom: 0; } }
  .header-content .mobile-feature {
    display: none;
    max-width: 100%;
    height: auto;
    margin: auto; }
    @media screen and (max-width: 768px) {
      .header-content .mobile-feature {
        display: block; } }
  .header-content .description {
    max-width: 546px;
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 6px;
    color: #FFFFFF;
    font-family: 'Lato', sans-serif; }
    @media screen and (max-width: 768px) {
      .header-content .description {
        margin-bottom: 10px; } }
  .header-content .profile {
    margin-bottom: 29px; }
    .header-content .profile__link {
      font-size: 16px;
      line-height: 1.5;
      color: #006DFE;
      text-decoration: none;
      font-family: 'LatoBold', sans-serif;
      transition: .3s; }
      .header-content .profile__link:hover {
        text-decoration: underline; }
  @media screen and (max-width: 768px) {
    .header-content .action-links {
      max-width: 250px; } }
  .header-content .action-links__phone {
    display: inline-block;
    vertical-align: middle;
    font-size: 18px;
    line-height: 1.33;
    color: #FFFFFF;
    text-decoration: none;
    font-family: 'LatoBold', sans-serif;
    transition: .3s; }
    .header-content .action-links__phone:hover {
      color: #006DFE;
      text-decoration: underline; }
  .header-content .action-links__email {
    display: inline-block;
    vertical-align: middle;
    font-size: 18px;
    line-height: 1.33;
    color: #FFFFFF;
    text-decoration: underline;
    font-family: 'LatoBold', sans-serif;
    transition: .3s; }
    .header-content .action-links__email:hover {
      color: #006DFE; }
  .header-content .action-links__separator {
    display: inline-block;
    vertical-align: middle;
    padding: 0 15px;
    font-size: 18px;
    line-height: 1.33;
    color: #006DFE;
    font-family: 'LatoBold', sans-serif; }
    @media screen and (max-width: 768px) {
      .header-content .action-links__separator {
        display: none; } }

.hamburger-wrapper {
  display: none; }
  @media screen and (max-width: 768px) {
    .hamburger-wrapper {
      display: block;
      padding: 15px 0 0;
      background-color: #090607; } }
  .hamburger-wrapper .hamburger {
    border: none;
    outline: none; }
    .hamburger-wrapper .hamburger:hover {
      opacity: 1; }
  .hamburger-wrapper .hamburger-inner {
    background: #ffffff; }
    .hamburger-wrapper .hamburger-inner::before {
      background: #ffffff; }
    .hamburger-wrapper .hamburger-inner::after {
      background: #ffffff; }
  .hamburger-wrapper .hamburger.is-active .hamburger-inner {
    background: #ffffff; }
    .hamburger-wrapper .hamburger.is-active .hamburger-inner::before {
      background: #ffffff; }
    .hamburger-wrapper .hamburger.is-active .hamburger-inner::after {
      background: #ffffff; }

body.menu-active-js {
  overflow: hidden; }

.navigation-mobile {
  position: absolute;
  z-index: 7;
  background: #090607;
  width: 100%;
  top: 70px;
  bottom: 0;
  left: -100%;
  transition: 0.5s linear;
  padding-top: 28px; }
  .navigation-mobile__item {
    font-family: 'Lato', sans-serif;
    text-align: center;
    margin-bottom: 27px; }
  .navigation-mobile__link {
    font-size: 18px;
    line-height: 1.25;
    color: #FFFFFF;
    text-decoration: none;
    transition: .3s; }
    .navigation-mobile__link:hover {
      color: #006DFE;
      text-decoration: underline; }

.menu-active-js .navigation-mobile {
  left: 0; }

.expectations-wrapper {
  margin-top: -280px;
  padding-top: 214px;
  background: #F5F7FA; }
  @media screen and (max-width: 1280px) {
    .expectations-wrapper {
      margin: 0;
      padding: 0; } }
  .expectations-wrapper .expectations-layout {
    display: flex; }
    @media screen and (max-width: 1280px) {
      .expectations-wrapper .expectations-layout {
        display: block; } }
  .expectations-wrapper .title {
    color: #102746;
    font-size: 35px;
    line-height: 1.2;
    font-family: 'LatoMedium', sans-serif;
    position: relative;
    z-index: 1; }
    .expectations-wrapper .title::before {
      content: "";
      position: absolute;
      z-index: 2;
      border-bottom: 2px solid #006DFE;
      width: 290px;
      bottom: -8px; }
    @media screen and (max-width: 1280px) {
      .expectations-wrapper .title {
        font-size: 30px;
        margin-bottom: 40px; }
        .expectations-wrapper .title::before {
          width: 270px;
          bottom: -8px; } }
    @media screen and (max-width: 768px) {
      .expectations-wrapper .title {
        font-size: 24px;
        margin-bottom: 50px; }
        .expectations-wrapper .title::before {
          width: 100%;
          bottom: -8px; } }
  .expectations-wrapper .expectations-list {
    width: 100%;
    max-width: 450px;
    padding-top: 41px;
    padding-bottom: 68px; }
    @media screen and (max-width: 1280px) {
      .expectations-wrapper .expectations-list {
        display: flex;
        flex-wrap: wrap;
        align-items: baseline;
        justify-content: space-between;
        padding-top: 0;
        padding-bottom: 0;
        max-width: 100%; } }
    @media screen and (max-width: 768px) {
      .expectations-wrapper .expectations-list {
        flex-direction: column; } }
  .expectations-wrapper .expectations__items {
    display: flex;
    margin-bottom: 30px;
    position: relative; }
    @media screen and (max-width: 1280px) {
      .expectations-wrapper .expectations__items {
        width: 50%;
        max-width: 460px; } }
    @media screen and (max-width: 768px) {
      .expectations-wrapper .expectations__items {
        width: 100%; } }
  .expectations-wrapper .expectations__img {
    position: absolute;
    left: 43px;
    top: 0;
    z-index: 2; }
    @media screen and (max-width: 1280px) {
      .expectations-wrapper .expectations__img {
        left: 0; } }
    .expectations-wrapper .expectations__img-healthy {
      left: 44px; }
      @media screen and (max-width: 1280px) {
        .expectations-wrapper .expectations__img-healthy {
          left: 0; } }
    .expectations-wrapper .expectations__img-fit {
      left: 35px; }
      @media screen and (max-width: 1280px) {
        .expectations-wrapper .expectations__img-fit {
          left: 0; } }
    .expectations-wrapper .expectations__img-trainee {
      left: 34px; }
      @media screen and (max-width: 1280px) {
        .expectations-wrapper .expectations__img-trainee {
          left: 0; } }
  .expectations-wrapper .expectations__title {
    font-size: 18px;
    line-height: 1.33;
    margin-bottom: 10px;
    color: #000;
    font-family: 'LatoMedium', sans-serif; }
    @media screen and (max-width: 1280px) {
      .expectations-wrapper .expectations__title {
        font-size: 16px; } }
    @media screen and (max-width: 768px) {
      .expectations-wrapper .expectations__title {
        font-size: 18px; } }
  .expectations-wrapper .expectations__desc {
    font-size: 16px;
    line-height: 1.6;
    color: #000;
    font-family: 'Lato', sans-serif; }
    @media screen and (max-width: 1280px) {
      .expectations-wrapper .expectations__desc {
        font-size: 14px; } }
    @media screen and (max-width: 768px) {
      .expectations-wrapper .expectations__desc {
        font-size: 16px; } }

.expectations__block {
  margin-left: 99px;
  position: relative;
  z-index: 1; }
  @media screen and (max-width: 1280px) {
    .expectations__block {
      margin-left: 60px; } }

.expectations-content {
  max-width: 1262px;
  margin: auto; }
  @media screen and (max-width: 1280px) {
    .expectations-content {
      padding: 40px 15px 0; } }

.expectations-img {
  margin-top: -214px; }
  @media screen and (max-width: 1300px) {
    .expectations-img {
      margin-left: 30px; } }
  @media screen and (max-width: 1280px) {
    .expectations-img {
      margin-top: 0; } }
  @media screen and (max-width: 768px) {
    .expectations-img {
      margin: 0; } }
  .expectations-img .image {
    display: block;
    max-width: 100%;
    height: auto; }
    @media screen and (max-width: 1280px) {
      .expectations-img .image {
        margin: auto;
        max-width: 60%; } }
    @media screen and (max-width: 768px) {
      .expectations-img .image {
        max-width: 100%; } }

.profit-wrapper {
  width: 100%;
  max-width: 1262px;
  padding: 93px 100px 100px;
  box-sizing: border-box;
  margin: auto;
  background: #ffffff;
  box-shadow: 0 2px 20px 0 rgba(21, 24, 36, 0.16);
  position: relative;
  top: -160px;
  z-index: 3; }
  @media screen and (max-width: 1280px) {
    .profit-wrapper {
      top: 0;
      padding: 40px 15px 40px; } }
  .profit-wrapper .title {
    color: #102746;
    font-size: 35px;
    line-height: 1.2;
    margin-bottom: 100px;
    font-family: 'LatoMedium', sans-serif; }
    @media screen and (max-width: 1280px) {
      .profit-wrapper .title {
        font-size: 30px;
        margin-bottom: 20px; } }
    @media screen and (max-width: 768px) {
      .profit-wrapper .title {
        font-size: 24px;
        margin-bottom: 25px;
        max-width: 260px; } }
  .profit-wrapper .profit-list {
    display: flex;
    align-items: baseline;
    justify-content: space-between; }
    @media screen and (max-width: 768px) {
      .profit-wrapper .profit-list {
        flex-direction: column; } }
  .profit-wrapper .profit__items {
    max-width: 317px; }
    @media screen and (max-width: 768px) {
      .profit-wrapper .profit__items {
        max-width: 100%; } }
  .profit-wrapper .profit__img {
    display: block;
    margin-bottom: 33px; }
    @media screen and (max-width: 1280px) {
      .profit-wrapper .profit__img {
        margin-bottom: 16px; } }
    @media screen and (max-width: 768px) {
      .profit-wrapper .profit__img {
        margin-bottom: 33px; } }
  .profit-wrapper .profit__desc {
    font-size: 16px;
    line-height: 1.6;
    color: #000;
    margin-bottom: 15px;
    font-family: 'Lato', sans-serif; }
    @media screen and (max-width: 1280px) {
      .profit-wrapper .profit__desc {
        font-size: 14px;
        margin-bottom: 10px; } }
    @media screen and (max-width: 768px) {
      .profit-wrapper .profit__desc {
        font-size: 16px;
        margin-bottom: 45px; } }
    @media screen and (max-width: 768px) {
      .profit-wrapper .profit__desc-mobile {
        font-size: 18px;
        margin-bottom: 15px;
        font-family: "LatoMedium", sans-serif; } }

.about-wrapper {
  padding: 100px 0; }
  @media screen and (max-width: 1280px) {
    .about-wrapper {
      padding: 40px 15px; } }
  .about-wrapper .title {
    color: #102746;
    font-size: 35px;
    line-height: 1.2;
    margin-bottom: 41px;
    font-family: 'LatoMedium', sans-serif;
    position: relative;
    z-index: 1; }
    .about-wrapper .title::before {
      content: "";
      position: absolute;
      z-index: 2;
      border-bottom: 2px solid #006DFE;
      width: 291px;
      bottom: -8px; }
    @media screen and (max-width: 1280px) {
      .about-wrapper .title {
        font-size: 30px; }
        .about-wrapper .title::before {
          width: 270px;
          bottom: -8px; } }
    @media screen and (max-width: 768px) {
      .about-wrapper .title {
        font-size: 24px; }
        .about-wrapper .title::before {
          width: 100%;
          bottom: -8px; } }
  .about-wrapper .about-list {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    flex-wrap: wrap; }
  .about-wrapper .about__items {
    max-width: 280px; }
    @media screen and (max-width: 1280px) {
      .about-wrapper .about__items {
        max-width: 400px; } }
    @media screen and (max-width: 1024px) {
      .about-wrapper .about__items {
        max-width: 350px; } }
    @media screen and (max-width: 768px) {
      .about-wrapper .about__items {
        margin-bottom: 18px;
        border-bottom: 1px solid #C4C4C4; } }
    @media screen and (max-width: 768px) {
      .about-wrapper .about__items-last {
        margin-bottom: 0;
        border-bottom: 0 none; } }
  .about-wrapper .about__desc {
    font-size: 16px;
    line-height: 1.6;
    color: #000;
    font-family: 'Lato', sans-serif; }
    @media screen and (max-width: 1280px) {
      .about-wrapper .about__desc {
        font-size: 14px;
        padding-bottom: 10px; } }
    @media screen and (max-width: 768px) {
      .about-wrapper .about__desc {
        font-size: 16px;
        padding-bottom: 18px; } }

.about-content {
  max-width: 1262px;
  margin: auto; }

.reviews-wrapper .site-layout {
  max-width: 1440px;
  overflow: hidden;
  position: relative;
  z-index: 1; }

.reviews-content-img {
  position: relative; }
  @media screen and (max-width: 768px) {
    .reviews-content-img {
      top: -40px; } }

.reviews-content {
  position: relative;
  z-index: 1; }
  .reviews-content .title {
    color: #102746;
    font-size: 35px;
    line-height: 1.2;
    font-family: 'LatoMedium', sans-serif;
    position: absolute;
    top: 57px;
    left: 89px;
    z-index: 1; }
    .reviews-content .title::before {
      content: "";
      position: absolute;
      z-index: 2;
      border-bottom: 2px solid #006DFE;
      width: 290px;
      bottom: -8px; }
    @media screen and (max-width: 1280px) {
      .reviews-content .title {
        font-size: 30px; }
        .reviews-content .title::before {
          width: 250px;
          bottom: -8px; } }
    @media screen and (max-width: 768px) {
      .reviews-content .title {
        font-size: 24px;
        padding: 0;
        position: relative;
        margin: 0 15px 40px;
        top: 0;
        left: 0; }
        .reviews-content .title::before {
          width: 100%;
          bottom: -8px; } }
  @media screen and (max-width: 768px) {
    .reviews-content__img {
      display: block;
      max-width: 100%;
      height: auto; } }

.reviews-slider {
  width: 100%;
  max-width: 1120px;
  position: absolute !important;
  top: 50%;
  left: 340px;
  transform: translateY(-50%);
  z-index: 2; }
  @media screen and (max-width: 1024px) {
    .reviews-slider {
      left: 240px; } }
  @media screen and (max-width: 768px) {
    .reviews-slider {
      position: relative !important;
      top: 0;
      left: 0;
      right: 0;
      max-width: 290px;
      transform: translateY(0%); } }
  .reviews-slider .slick-track {
    padding: 60px 0; }
    @media screen and (max-width: 768px) {
      .reviews-slider .slick-track {
        padding: 0; } }
  .reviews-slider .slick-list {
    padding-left: 20px !important; }
    @media screen and (max-width: 768px) {
      .reviews-slider .slick-list {
        padding: 0 !important; } }
  .reviews-slider .slick-dots {
    text-align: left;
    margin-left: 337px; }
    @media screen and (max-width: 768px) {
      .reviews-slider .slick-dots {
        text-align: center;
        margin: 0 !important;
        bottom: -40px; } }
    .reviews-slider .slick-dots li {
      margin-right: 20px; }
    .reviews-slider .slick-dots li button::before {
      content: "";
      opacity: 1;
      color: transparent;
      background: transparent;
      border: 3px solid #fff;
      border-radius: 100%; }
    .reviews-slider .slick-dots li.slick-active button:before {
      background: #fff; }
  .reviews-slider .slick-slide {
    width: 100% !important;
    max-width: 420px !important;
    min-height: 500px;
    padding: 100px 40px 50px;
    margin-left: 40px;
    background: #ffffff url("../img/bitmap/blue-quotes.svg") no-repeat 40px 50px;
    border-radius: 6px;
    box-sizing: border-box;
    box-shadow: 0 12px 30px 0 rgba(121, 121, 121, 0.3); }
    @media screen and (max-width: 768px) {
      .reviews-slider .slick-slide {
        max-width: 290px !important;
        margin: 0;
        min-height: 610px;
        padding: 100px 15px 20px;
        background: #ffffff url("../img/bitmap/blue-quotes.svg") no-repeat 15px 50px; } }
  .reviews-slider .slick-slide.slick-current {
    background: #006DFE url("../img/bitmap/white-quotes.svg") no-repeat 40px 50px;
    box-shadow: 0 12px 30px 0 rgba(0, 109, 254, 0.5); }
    @media screen and (max-width: 768px) {
      .reviews-slider .slick-slide.slick-current {
        max-width: 290px !important;
        background: #006DFE url("../img/bitmap/white-quotes.svg") no-repeat 15px 50px; } }
    .reviews-slider .slick-slide.slick-current .slider-block .text {
      color: #ffffff; }
    .reviews-slider .slick-slide.slick-current .slider-block .author-content .name {
      color: #ffffff; }
    .reviews-slider .slick-slide.slick-current .slider-block .author-content .surname {
      color: #ffffff; }

.slider-block .text {
  font-size: 16px;
  line-height: 1.7;
  margin-bottom: 20px;
  color: rgba(0, 0, 0, 0.7);
  font-family: "Lato", sans-serif; }
  @media screen and (max-width: 1280px) {
    .slider-block .text {
      font-size: 14px; } }
  @media screen and (max-width: 768px) {
    .slider-block .text {
      font-size: 16px; } }

.slider-block .author {
  display: flex; }
  .slider-block .author-content .name {
    font-size: 18px;
    line-height: 1.22;
    margin-bottom: 5px;
    color: #000;
    font-family: "Lato", sans-serif; }
    @media screen and (max-width: 1280px) {
      .slider-block .author-content .name {
        font-size: 16px; } }
  .slider-block .author-content .surname {
    font-size: 10px;
    line-height: 1.5;
    color: #999999;
    font-family: "LatoBold", sans-serif; }
  .slider-block .author .image {
    display: block;
    margin-right: 18px; }

@media screen and (max-width: 768px) {
  .wrapper-slider {
    display: flex;
    flex-direction: column-reverse; } }

.slick-dotted.slick-slider {
  margin: 0 auto !important; }

.classes-wrapper .title {
  color: #102746;
  font-size: 35px;
  line-height: 1.2;
  margin-bottom: 41px;
  font-family: 'LatoMedium', sans-serif;
  position: relative;
  z-index: 1; }
  .classes-wrapper .title::before {
    content: "";
    position: absolute;
    z-index: 2;
    border-bottom: 2px solid #006DFE;
    width: 291px;
    bottom: -8px; }
  @media screen and (max-width: 1280px) {
    .classes-wrapper .title {
      font-size: 30px; }
      .classes-wrapper .title::before {
        width: 270px;
        bottom: -8px; } }
  @media screen and (max-width: 768px) {
    .classes-wrapper .title {
      font-size: 24px; }
      .classes-wrapper .title::before {
        width: 100%;
        bottom: -8px; } }

.classes-wrapper .classes-list {
  display: flex;
  align-items: baseline;
  justify-content: space-between; }
  @media screen and (max-width: 1280px) {
    .classes-wrapper .classes-list {
      flex-wrap: wrap; } }

.classes-wrapper .classes__items {
  max-width: 280px; }
  @media screen and (max-width: 1280px) {
    .classes-wrapper .classes__items {
      max-width: 470px; } }
  @media screen and (max-width: 1024px) {
    .classes-wrapper .classes__items {
      max-width: 350px; } }

.classes-wrapper .classes__img {
  display: block;
  max-width: 100%;
  height: auto;
  margin-bottom: 33px; }
  @media screen and (max-width: 1280px) {
    .classes-wrapper .classes__img {
      margin-bottom: 14px; } }
  @media screen and (max-width: 768px) {
    .classes-wrapper .classes__img {
      margin-bottom: 33px; } }

.classes-wrapper .classes__desc {
  font-size: 16px;
  line-height: 1.6;
  color: #000;
  font-family: 'Lato', sans-serif; }
  @media screen and (max-width: 1280px) {
    .classes-wrapper .classes__desc {
      font-size: 14px;
      margin-bottom: 20px; } }
  @media screen and (max-width: 768px) {
    .classes-wrapper .classes__desc {
      font-size: 16px;
      margin-bottom: 40px; } }

.classes-content {
  max-width: 1262px;
  padding: 100px 0;
  margin: auto; }
  @media screen and (max-width: 1280px) {
    .classes-content {
      padding: 10px 15px 40px; } }

.footer-contact {
  background: #D7E0EE;
  padding: 84px 0 85px; }
  @media screen and (max-width: 1280px) {
    .footer-contact {
      padding: 40px 0; } }

.footer-wrapper {
  max-width: 1062px;
  margin: auto; }
  @media screen and (max-width: 1280px) {
    .footer-wrapper {
      padding: 0 15px; } }
  .footer-wrapper .blocks {
    display: flex;
    align-items: baseline;
    justify-content: space-between; }
    @media screen and (max-width: 768px) {
      .footer-wrapper .blocks {
        flex-direction: column; } }
  .footer-wrapper .title {
    color: #102746;
    font-size: 35px;
    line-height: 1.2;
    font-family: 'LatoMedium', sans-serif;
    position: relative;
    z-index: 1; }
    .footer-wrapper .title::before {
      content: "";
      position: absolute;
      z-index: 2;
      border-bottom: 2px solid #006DFE;
      width: 147px;
      bottom: -8px; }
    @media screen and (max-width: 1280px) {
      .footer-wrapper .title {
        font-size: 30px; }
        .footer-wrapper .title::before {
          width: 137px;
          bottom: -8px; } }
    @media screen and (max-width: 768px) {
      .footer-wrapper .title {
        font-size: 24px;
        margin-bottom: 40px;
        width: 100%; }
        .footer-wrapper .title::before {
          width: 100%;
          bottom: -8px; } }
  .footer-wrapper .footer__desc {
    font-size: 20px;
    line-height: 1.8;
    color: #102746;
    font-family: "LatoBold", sans-serif; }
    @media screen and (max-width: 1280px) {
      .footer-wrapper .footer__desc {
        font-size: 18px; } }
  .footer-wrapper .footer__link {
    color: #006DFE;
    font-family: "Lato", sans-serif; }

.footer-nav {
  padding: 78px 0 55px;
  background-color: #000000; }
  @media screen and (max-width: 1280px) {
    .footer-nav {
      padding: 40px 15px 30px; } }

.footer-wrapper-nav {
  max-width: 1062px;
  margin: auto; }
  .footer-wrapper-nav .blocks,
  .footer-wrapper-nav .blocks-terms {
    display: flex;
    align-items: baseline;
    justify-content: space-between; }
  .footer-wrapper-nav .blocks {
    padding-bottom: 142px; }
    @media screen and (max-width: 1280px) {
      .footer-wrapper-nav .blocks {
        padding-bottom: 72px; } }
    @media screen and (max-width: 768px) {
      .footer-wrapper-nav .blocks {
        flex-direction: column; } }
  .footer-wrapper-nav .blocks-terms .terms-text {
    font-size: 12px;
    line-height: 15px;
    color: #CACACA;
    opacity: .5;
    font-family: "Lato", sans-serif; }
  .footer-wrapper-nav .blocks-terms .terms-link {
    font-size: 12px;
    line-height: 15px;
    color: #CACACA;
    opacity: .5;
    text-decoration: none;
    font-family: "Lato", sans-serif; }

@media screen and (max-width: 768px) {
  .footer-navigation {
    width: 100%; } }

.footer-navigation__item {
  display: inline-block;
  vertical-align: top;
  padding-right: 31px;
  font-family: 'Lato', sans-serif; }
  .footer-navigation__item:last-child {
    padding-right: 0; }
  @media screen and (max-width: 768px) {
    .footer-navigation__item {
      display: block;
      text-align: center;
      padding-right: 0;
      margin-bottom: 27px; } }

.footer-navigation__link {
  font-size: 12px;
  line-height: 1.25;
  color: #FFFFFF;
  text-decoration: none;
  transition: .3s; }
  .footer-navigation__link:hover {
    color: #006DFE;
    text-decoration: underline; }
  @media screen and (max-width: 768px) {
    .footer-navigation__link {
      font-size: 18px;
      text-align: center; } }

.footer-social {
  display: flex;
  align-items: center; }
  @media screen and (max-width: 768px) {
    .footer-social {
      margin: 0 auto 45px; } }
  .footer-social__item {
    padding-right: 25px; }
    .footer-social__item :last-child {
      padding-right: 0; }
    @media screen and (max-width: 768px) {
      .footer-social__item {
        padding: 0 12px; } }
  .footer-social__link {
    display: block;
    height: 21px;
    text-decoration: none; }
  .footer-social__link-fb {
    background: url("../img/sprite/sprite-social.svg") no-repeat 0 -2px;
    width: 8px;
    height: 18px; }
  .footer-social__link-in {
    background: url("../img/sprite/sprite-social.svg") no-repeat -33px 0;
    width: 21px; }
  .footer-social__link-vk {
    background: url("../img/sprite/sprite-social.svg") no-repeat -78px 0;
    width: 35px; }
  .footer-social__link-tw {
    background: url("../img/sprite/sprite-social.svg") no-repeat -137px -2px;
    width: 23px; }
