.profit-wrapper {
    width: 100%;
    max-width: 1262px;
    padding: 93px 100px 100px;
    box-sizing: border-box;
    margin: auto;
    background: #ffffff;
    box-shadow: 0 2px 20px 0 rgba(21, 24, 36, 0.16);
    position: relative;
    top: -160px;
    z-index: 3;
    @media screen and (max-width: 1280px) {
        top: 0;
        padding: 40px 15px 40px;
    }

    .title {
        color: #102746;
        font-size: 35px;
        line-height: 1.2;
        margin-bottom: 100px;
        font-family: 'LatoMedium', sans-serif;
        @media screen and (max-width: 1280px) {
            font-size: 30px;
            margin-bottom: 20px;
        }
        @media screen and (max-width: 768px) {
            font-size: 24px;
            margin-bottom: 25px;
            max-width: 260px;
        }
    }

    .profit {
        &-list {
            display: flex;
            align-items: baseline;
            justify-content: space-between;
            @media screen and (max-width: 768px) {
                flex-direction: column;
            }
        }

        &__items {
            max-width: 317px;
            @media screen and (max-width: 768px) {
                max-width: 100%;
            }
        }

        &__img {
            display: block;
            margin-bottom: 33px;
            @media screen and (max-width: 1280px) {
                margin-bottom: 16px;
            }
            @media screen and (max-width: 768px) {
                margin-bottom: 33px;
            }
        }

        &__desc {
            font-size: 16px;
            line-height: 1.6;
            color: #000;
            margin-bottom: 15px;
            font-family: 'Lato', sans-serif;
            @media screen and (max-width: 1280px) {
                font-size: 14px;
                margin-bottom: 10px;
            }
            @media screen and (max-width: 768px) {
                font-size: 16px;
                margin-bottom: 45px;
            }

            &-mobile {
                @media screen and (max-width: 768px) {
                    font-size: 18px;
                    margin-bottom: 15px;
                    font-family: "LatoMedium", sans-serif;
                }
            }
        }

    }
}