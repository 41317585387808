.header {
    padding: 0 15px;
    background-color: #090607;
    position: relative;
}

.header-wrapper {
    width: 100%;
    max-width: 1062px;
    margin: auto;
    position: relative;
    z-index: 1;
    &::before {
        position: absolute;
        content: "";
        z-index: -1;
        width: 1110px;
        height: 740px;
        background-image: url("../img/feature/main-feature.png");
        background-repeat: no-repeat;
        background-size: contain;
        background-position-x: 160px;
        background-position-y: 0;
        top: 0;
        left: 128px;
        @media screen and (max-width: 1280px) {
            background-image: none;
            background-position: 0 0;
            top: 0;
            left: 0;
            width: 0;
            height: 0;
        }
    }
}

.navigation {
    padding-top: 64px;
    @media screen and (max-width: 1280px) {
        padding-top: 40px;
        text-align: center;
    }
    @media screen and (max-width: 768px) {
        display: none;
    }

    &__item {
        display: inline-block;
        vertical-align: top;
        padding-right: 31px;
        font-family: 'Lato', sans-serif;
        &:last-child {
            padding-right: 0;
        }
    }

    &__link {
        font-size: 12px;
        line-height: 1.25;
        color: #FFFFFF;
        text-decoration: none;
        transition:.3s;
        &:hover {
            color: #006DFE;
            text-decoration: underline;
        }
    }
}

.header-content {
    width: 100%;
    max-width: 620px;
    padding: 138px 0 316px;
    @media screen and (max-width: 1280px) {
        margin: auto;
        padding: 50px 0;
    }
    @media screen and (max-width: 768px) {
        margin: auto;
        padding: 10px 0 50px;
    }

    .title {
        font-size: 50px;
        line-height: 1.2;
        margin-bottom: 22px;
        color: #FFFFFF;
        font-family: 'LatoMedium', sans-serif;
        @media screen and (max-width: 768px) {
            font-size: 32px;
            margin-bottom: 5px;
            max-width: 290px;
        }
    }

    .sub-title {
        font-size: 24px;
        line-height: 1.2;
        margin-bottom: 15px;
        color: #FFFFFF;
        font-family: 'LatoMedium', sans-serif;
        @media screen and (max-width: 768px) {
            font-size: 18px;
            margin-bottom: 0;
        }
    }

    .mobile-feature {
        display: none;
        max-width: 100%;
        height: auto;
        margin: auto;

        @media screen and (max-width: 768px) {
            display: block;
        }
    }

    .description {
        max-width: 546px;
        font-size: 16px;
        line-height: 1.5;
        margin-bottom: 6px;
        color: #FFFFFF;
        font-family: 'Lato', sans-serif;
        @media screen and (max-width: 768px) {
            margin-bottom: 10px;
        }
    }

    .profile {
        margin-bottom: 29px;

        &__link {
            font-size: 16px;
            line-height: 1.5;
            color: #006DFE;
            text-decoration: none;
            font-family: 'LatoBold', sans-serif;
            transition:.3s;
            &:hover {
                text-decoration: underline;
            }
        }
    }

    .action-links {
        @media screen and (max-width: 768px) {
            max-width: 250px;
        }

        &__phone {
            display: inline-block;
            vertical-align: middle;
            font-size: 18px;
            line-height: 1.33;
            color: #FFFFFF;
            text-decoration: none;
            font-family: 'LatoBold', sans-serif;
            transition:.3s;
            &:hover {
                color: #006DFE;
                text-decoration: underline;
            }
        }

        &__email {
            display: inline-block;
            vertical-align: middle;
            font-size: 18px;
            line-height: 1.33;
            color: #FFFFFF;
            text-decoration: underline;
            font-family: 'LatoBold', sans-serif;
            transition:.3s;
            &:hover {
                color: #006DFE;
            }
        }

        &__separator {
            display: inline-block;
            vertical-align: middle;
            padding: 0 15px;
            font-size: 18px;
            line-height: 1.33;
            color: #006DFE;
            font-family: 'LatoBold', sans-serif;
            @media screen and (max-width: 768px) {
                display: none;
            }
        }
    }
}

.hamburger-wrapper {
    display: none;
    @media screen and (max-width: 768px) {
        display: block;
        padding: 15px 0 0;
        background-color: #090607;
    }
    .hamburger {
        border: none;
        outline: none;
        &:hover {
            opacity: 1;
        }
    }

    .hamburger-inner {
        background: #ffffff;
        &::before {
            background: #ffffff;
        }
        &::after {
            background: #ffffff;
        }
    }
    .hamburger.is-active .hamburger-inner {
        background: #ffffff;
        &::before {
            background: #ffffff;
        }
        &::after {
            background: #ffffff;
        }
    }
}

body.menu-active-js {
    overflow: hidden;
}

.navigation-mobile {
    position: absolute;
    z-index: 7;
    background: #090607;
    width: 100%;
    top: 70px;
    bottom: 0;
    left: -100%;
    transition: 0.5s linear;
    padding-top: 28px;
    &__item {
        font-family: 'Lato', sans-serif;
        text-align: center;
        margin-bottom: 27px;
    }

    &__link {
        font-size: 18px;
        line-height: 1.25;
        color: #FFFFFF;
        text-decoration: none;
        transition:.3s;
        &:hover {
            color: #006DFE;
            text-decoration: underline;
        }
    }
}

.menu-active-js .navigation-mobile {
    left: 0;
}