.expectations-wrapper {
    margin-top: -280px;
    padding-top: 214px;
    background: #F5F7FA;
    @media screen and (max-width: 1280px) {
        margin: 0;
        padding: 0;
    }

    .expectations-layout {
        display: flex;
        @media screen and (max-width: 1280px) {
            display: block;
        }
    }

    .title {
        color: #102746;
        font-size: 35px;
        line-height: 1.2;
        font-family: 'LatoMedium', sans-serif;
        position: relative;
        z-index: 1;
        &::before {
            content: "";
            position: absolute;
            z-index: 2;
            border-bottom: 2px solid #006DFE;
            width: 290px;
            bottom: -8px;
        }
        @media screen and (max-width: 1280px) {
            font-size: 30px;
            margin-bottom: 40px;
            &::before {
                width: 270px;
                bottom: -8px;
            }
        }
        @media screen and (max-width: 768px) {
            font-size: 24px;
            margin-bottom: 50px;
            &::before {
                width: 100%;
                bottom: -8px;
            }
        }
    }

    .expectations {
        &-list {
            width: 100%;
            max-width: 450px;
            padding-top: 41px;
            padding-bottom: 68px;
            @media screen and (max-width: 1280px) {
                display: flex;
                flex-wrap: wrap;
                align-items: baseline;
                justify-content: space-between;
                padding-top: 0;
                padding-bottom: 0;
                max-width: 100%;
            }
            @media screen and (max-width: 768px) {
                flex-direction: column;
            }
        }

        &__items {
            display: flex;
            margin-bottom: 30px;
            position: relative;
            @media screen and (max-width: 1280px) {
                width: 50%;
                max-width: 460px;
            }
            @media screen and (max-width: 768px) {
                width: 100%;
            }
        }

        &__img {
            position: absolute;
            left: 43px;
            top: 0;
            z-index: 2;
            @media screen and (max-width: 1280px) {
                left: 0;
            }

            &-healthy {
                left: 44px;
                @media screen and (max-width: 1280px) {
                    left: 0;
                }
            }

            &-fit {
                left: 35px;
                @media screen and (max-width: 1280px) {
                    left: 0;
                }
            }

            &-trainee {
                left: 34px;
                @media screen and (max-width: 1280px) {
                    left: 0;
                }
            }
        }

        &__title {
            font-size: 18px;
            line-height: 1.33;
            margin-bottom: 10px;
            color: #000;
            font-family: 'LatoMedium', sans-serif;
            @media screen and (max-width: 1280px) {
                font-size: 16px;
            }
            @media screen and (max-width: 768px) {
                font-size: 18px;
            }
        }

        &__desc {
            font-size: 16px;
            line-height: 1.6;
            color: #000;
            font-family: 'Lato', sans-serif;
            @media screen and (max-width: 1280px) {
                font-size: 14px;
            }
            @media screen and (max-width: 768px) {
                font-size: 16px;
            }
        }

    }
}

.expectations__block {
    margin-left: 99px;
    position: relative;
    z-index: 1;
    @media screen and (max-width: 1280px) {
        margin-left: 60px;
    }
}

.expectations-content {
    max-width: 1262px;
    margin: auto;
    @media screen and (max-width: 1280px) {
        padding: 40px 15px 0;
    }
}

.expectations-img {
    margin-top: -214px;
    @media screen and (max-width: 1300px) {
        margin-left: 30px;
    }
    @media screen and (max-width: 1280px) {
        margin-top: 0;
    }
    @media screen and (max-width: 768px) {
        margin: 0;
    }
    .image {
        display: block;
        max-width: 100%;
        height: auto;
        @media screen and (max-width: 1280px) {
            margin: auto;
            max-width: 60%;
        }
        @media screen and (max-width: 768px) {
            max-width: 100%;
        }
    }
}