.footer-contact {
    background: #D7E0EE;
    padding: 84px 0 85px;
    @media screen and (max-width: 1280px) {
        padding: 40px 0;
    }
}

.footer-wrapper {
    max-width: 1062px;
    margin: auto;
    @media screen and (max-width: 1280px) {
        padding: 0 15px;
    }

    .blocks {
        display: flex;
        align-items: baseline;
        justify-content: space-between;
        @media screen and (max-width: 768px) {
            flex-direction: column;
        }
    }

    .title {
        color: #102746;
        font-size: 35px;
        line-height: 1.2;
        font-family: 'LatoMedium', sans-serif;
        position: relative;
        z-index: 1;
        &::before {
            content: "";
            position: absolute;
            z-index: 2;
            border-bottom: 2px solid #006DFE;
            width: 147px;
            bottom: -8px
        }
        @media screen and (max-width: 1280px) {
            font-size: 30px;
            &::before {
                width: 137px;
                bottom: -8px
            }
        }
        @media screen and (max-width: 768px) {
            font-size: 24px;
            margin-bottom: 40px;
            width: 100%;
            &::before {
                width: 100%;
                bottom: -8px
            }
        }
    }

    .footer__desc {
        font-size: 20px;
        line-height: 1.8;
        color: #102746;
        font-family: "LatoBold", sans-serif;
        @media screen and (max-width: 1280px) {
            font-size: 18px;
        }
    }
    
    .footer__link {
        color: #006DFE;
        font-family: "Lato", sans-serif;
    }
}

.footer-nav {
    padding: 78px 0 55px;
    background-color: #000000;
    @media screen and (max-width: 1280px) {
        padding: 40px 15px 30px;
    }
}

.footer-wrapper-nav {
    max-width: 1062px;
    margin: auto;
    .blocks,
    .blocks-terms {
        display: flex;
        align-items: baseline;
        justify-content: space-between;
    }

    .blocks {
        padding-bottom: 142px;
        @media screen and (max-width: 1280px) {
            padding-bottom: 72px;
        }
        @media screen and (max-width: 768px) {
            flex-direction: column;
        }
    }

    .blocks-terms {
        .terms-text {
            font-size: 12px;
            line-height: 15px;
            color: #CACACA;
            opacity: .5;
            font-family: "Lato", sans-serif;
        }
        .terms-link {
            font-size: 12px;
            line-height: 15px;
            color: #CACACA;
            opacity: .5;
            text-decoration: none;
            font-family: "Lato", sans-serif;
        }
    }
}

.footer-navigation {
    @media screen and (max-width: 768px) {
        width: 100%;
    }

    &__item {
        display: inline-block;
        vertical-align: top;
        padding-right: 31px;
        font-family: 'Lato', sans-serif;
        &:last-child {
            padding-right: 0;
        }
        @media screen and (max-width: 768px) {
            display: block;
            text-align: center;
            padding-right: 0;
            margin-bottom: 27px;
        }
    }

    &__link {
        font-size: 12px;
        line-height: 1.25;
        color: #FFFFFF;
        text-decoration: none;
        transition:.3s;
        &:hover {
            color: #006DFE;
            text-decoration: underline;
        }

        @media screen and (max-width: 768px) {
            font-size: 18px;
            text-align: center;
        }
    }
}

.footer-social {
    display: flex;
    align-items: center;
    @media screen and (max-width: 768px) {
        margin: 0 auto 45px;
    }

    &__item {
        padding-right: 25px;
        :last-child {
            padding-right: 0;
        }
        @media screen and (max-width: 768px) {
            padding: 0 12px;
        }
    }

    &__link {
        display: block;
        height: 21px;
        text-decoration: none;
    }

    &__link-fb {
        background: url('../img/sprite/sprite-social.svg') no-repeat 0 -2px;
        width: 8px;
        height: 18px;
    }

    &__link-in {
        background: url('../img/sprite/sprite-social.svg') no-repeat -33px 0;
        width: 21px;
    }

    &__link-vk {
        background: url('../img/sprite/sprite-social.svg') no-repeat -78px 0;
        width: 35px;
    }

    &__link-tw {
        background: url('../img/sprite/sprite-social.svg') no-repeat -137px -2px;
        width: 23px;
    }
}